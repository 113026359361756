<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>Program</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Program
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn
                                        v-if="checkIsAccessible('academic-program', 'create')"
                                            @click="__create()"
                                            class="mt-1 btn btn-primary"
                                    >
                                        <v-icon elevation="2" outlined small>fas fa-plus</v-icon>&nbsp;
                                        Add Program
                                    </v-btn>
                                    <v-btn
                                        v-if="checkIsAccessible('institution', 'list')"
                                        @click="redirectToInstitution()"
                                        class="mt-1 mx-3 btn btn-standard"
                                    >
                                        Universities
                                    </v-btn>
                                  <b-dropdown size="sm" variant="button"  v-if="checkIsAccessible('academic-program', 'edit')"
                                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon " no-caret
                                              right no-flip>
                                    <template v-slot:button-content>
                                      <i class="ki ki-bold-more-hor"></i>
                                    </template>
                                    <!--begin::Navigation-->
                                    <div class="navi navi-hover ">

                                      <b-dropdown-text tag="div" class="navi-item">
                                        <a @click="updateSessionAndIntake('session')" class="navi-link">

                                          <span class="navi-text">Update session</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item">
                                        <a @click="updateSessionAndIntake('session_and_location')" class="navi-link">

                                          <span class="navi-text">Update location for session</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item">
                                        <a @click="updateSessionAndIntake('intake')" class="navi-link">

                                          <span class="navi-text">Update intake</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item">
                                        <a @click="updateSessionAndIntake('featured')" class="navi-link">

                                          <span class="navi-text">Update featured</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item">
                                        <a @click="updateSessionAndIntake('pgwp')" class="navi-link">

                                          <span class="navi-text">Update PGWP</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item">
                                        <a @click="updateSessionAndIntake('status')" class="navi-link">

                                          <span class="navi-text">Update status</span>
                                        </a>
                                      </b-dropdown-text>
                                    </div>
                                    <!--end::Navigation-->
                                  </b-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <v-row>
                                <v-col cols="12" md="3" sm="6">
                                    <v-text-field
                                            @input="search.title = $event !== null ? $event : ''"
                                            clearable
                                            dense
                                            label="Name"
                                            outlined
                                            v-model="search.title"
                                            v-on:keyup.enter="__search"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3" sm="6">
                                    <v-select
                                            :items="categories"
                                            @input="search.institution_id = $event !== null ? $event : ''"
                                            clearable
                                            dense
                                            item-text="title"
                                            item-value="id"
                                            label="University"
                                            outlined
                                            v-model="search.institution_id"
                                            v-on:keyup.enter="__search"
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3" sm="6">
                                    <v-select
                                            :items="education_levels"
                                            @input="search.education_level_id = $event !== null ? $event : ''"
                                            clearable
                                            dense
                                            item-text="title"
                                            item-value="id"
                                            label="Education Level"
                                            outlined
                                            v-model="search.education_level_id"
                                            v-on:keyup.enter="__search"
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3" sm="6">
                                  <v-select
                                      :items="intakeStatus"
                                      @input="search.application_intake_status = $event !== null ? $event : ''"
                                      clearable
                                      dense
                                      item-text="name"
                                      item-value="value"
                                      label="Application Intake Status"
                                      outlined
                                      v-model="search.application_intake_status"
                                      v-on:keyup.enter="__search"
                                  ></v-select>
                                </v-col>

                                <v-col cols="12" md="3" sm="6">
                                    <v-select
                                            :items="['Yes', 'No']"
                                            @input="search.required_document = $event !== null ? $event : ''"
                                            clearable
                                            dense
                                            item-text="name"
                                            item-value="value"
                                            label="Checklist document"
                                            outlined
                                            v-model="search.required_document"
                                            v-on:keyup.enter="__search"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="3" sm="6">
                                    <v-select
                                            :items="status"
                                            @input="search.is_active = $event !== null ? $event : ''"
                                            clearable
                                            dense
                                            item-text="name"
                                            item-value="value"
                                            label="Status"
                                            outlined
                                            v-model="search.is_active"
                                            v-on:keyup.enter="__search"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-select
                                      :items="locations"
                                      @input="search.location_id = $event !== null ? $event : ''"
                                      clearable
                                      dense
                                      item-text="full_address"
                                      item-value="id"
                                      label="Location"
                                      outlined
                                      v-model="search.location_id"
                                      v-on:keyup.enter="__search"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-btn
                                      :loading="loading"
                                      @click.prevent="__search"
                                      class="mt-1 btn btn-primary float-right"
                                    >
                                        <v-icon elevation="2" outlined small>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <div class="table-responsive">
                            <v-skeleton-loader
                                    type="table-thead"
                                    v-if="loading"
                            >
                            </v-skeleton-loader>

                            <v-skeleton-loader
                                    type="table-row-divider@25"
                                    v-if="loading"
                            >
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                  <tr class="px-3">
                                      <th><v-checkbox v-model="allselect" @click="setAllProgramIds" ></v-checkbox></th>
                                      <th :class="sortedClass('name')" @click="sortBy('name')" class="px-3"><strong>Name</strong></th>
                                      <th><strong>Additional info</strong></th>
                                      <th><strong>Locations info</strong></th>
                                      <th><strong>Sessions</strong></th>
                                      <th><strong>Status info</strong></th>
                                      <th><strong>Last updated</strong></th>
                                      <th class="pr-3 text-center"><strong>Action</strong></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template>
                                    <tr :key="program.id" v-for="program in programs">
                                      <td>
                                          <v-checkbox v-model="ids" :value="program.id"></v-checkbox>
                                      </td>
                                      <td>
                                          <a @click="__edit(program.id)"
                                             class="text-primary font-weight-bolder mb-1 font-size-lg" v-html="program.title_location">
                                          </a>
                                      </td>
                                      <td>
                                        <p v-if="program.is_pgwp">
                                          <i class="fas fa-check-circle text-success"></i> <b>PGWP & </b>
                                          <span><b>CIP code:</b> {{program.cip_code}}</span>
                                        </p>
                                        <div class="font-size-lg">
                                          <b>Institution: </b> <span class="badge badge-info text-lg">{{ program.institution_name ? program.institution_name : '--' }}</span>
                                        </div>
                                        <br>
                                        <div class="font-size-lg">
                                          <b>Level: </b> <span>{{ program.education_level || 'N/A' }}</span> <br>
                                          <b>Program duration: </b> <span >{{ program.program_duration || 'N/A' }} {{ program.duration_unit || 'N/A' }}</span>
                                        </div>
                                        <div class="font-size-lg">
                                          <b>Faculty: </b>
                                          <div class="ml-8" v-if="program.categories.length > 0">
                                            <ul>
                                              <li  v-for="(category, index) in program.categories" :key="index">{{category}}</li>
                                            </ul>
                                          </div>
                                          <span v-else>N/A</span>
                                        </div>
                                      </td>
                                      <td>
                                        <span v-if="program.locations" v-html="program.locations"></span>
                                        <span v-else>N/A</span>
                                      </td>
                                      <td>
                                          <ul v-if="program && program.all_sessions && program.all_sessions.length > 0">
                                            <li v-for="(session, index) in program.all_sessions" :key="index">{{session.title}}</li>
                                          </ul>
                                          <span v-else>N/A</span>
                                      </td>

                                      <td>
                                        <p>
                                          <strong>Is Featured: </strong>
                                          <span class="text-lg badge" v-bind:class="{ 'badge-success': program.is_featured, 'badge-danger': !program.is_featured }">
                                            {{ program.is_featured ? 'Yes' : 'No' }}
                                          </span>
                                        </p>
                                        <p>
                                          <strong>Sessions: </strong>
                                          <span class="text-lg badge"v-bind:class="{ 'badge-success': program.application_intake_status_text === 'Open', 'badge-danger': program.application_intake_status_text === 'Closed', 'badge-warning': program.application_intake_status_text === 'Waitlisted' }">
                                            {{ program.application_intake_status_text }}
                                          </span>
                                        </p>
                                        <p>
                                          <strong>Doc's Checklist: </strong>
                                          <span class="badge" v-bind:class="{ 'badge-success': program.checklist_document, 'badge-danger': !program.checklist_document }">
                                            {{ program.checklist_document ? 'Yes' : 'No' }}
                                          </span>
                                        </p>
                                         <p>
                                           <strong>Status: </strong>
                                           <span class="text-lg badge" v-bind:class="{ 'badge-success': program.is_active, 'badge-danger': !program.is_active }">
                                             {{ program.is_active ? 'Active' : 'Inactive' }}
                                           </span>
                                         </p>
                                      </td>
                                      <td>
                                        {{ program.last_updated }}
                                      </td>
                                      <td class="pr-0 text-left">
                                          <template>
                                              <b-dropdown
                                                      no-caret
                                                      no-flip
                                                      right
                                                      size="sm"
                                                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                      variant="link"
                                              >
                                                  <template v-slot:button-content>
                                                      <i class="ki ki-bold-more-hor"></i>
                                                  </template>
                                                  <!--begin::Navigation-->
                                                  <div class="navi navi-hover min-w-md-250px">
                                                      <b-dropdown-text class="navi-item" tag="div"  v-if="checkIsAccessible('academic-program', 'edit')">
                                                                                          <a @click="__edit(program.id)" class="navi-link">
                                                              <span class="navi-icon">
                                                                  <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                                              </span>
                                                              <span class="navi-text">Edit</span>
                                                          </a>
                                                      </b-dropdown-text>
                                                      <b-dropdown-text class="navi-item" tag="div"  v-if="checkIsAccessible('academic-program', 'delete')">
                                                          <a @click.prevent="__delete(program.id)" class="navi-link">
                                                              <span class="navi-icon">
                                                                  <v-icon color="red darken-2">fas fa-trash</v-icon>
                                                              </span>
                                                              <span class="navi-text">Delete</span>
                                                          </a>
                                                      </b-dropdown-text>
                                                  </div>
                                              </b-dropdown>
                                          </template>
                                      </td>
                                    </tr>
                                    <tr v-if="programs.length == 0">
                                        <td class="text-center" colspan="15"><strong>No Data Found</strong></td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                            <b-pagination
                                    :disabled="loading"
                                    :per-page="perPage"
                                    :total-rows="total"
                                    @input="__getAll"
                                    class="pull-right mt-7"
                                    first-number
                                    last-number
                                    v-if="programs.length > 0"
                                    v-model="page"
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <v-dialog v-model="dialog" max-width="850" persistent>
        <v-card>
          <v-card-title>
            Update program
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-if="field == 'session_and_location' || field == 'session'">
                <v-autocomplete
                    :error="$v.program.session_id.$error"
                    v-model="program.session_id"
                    :items="program_sessions"
                    @change="getLocationByInstitution"
                    item-text="title"
                    item-value="id"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    outlined
                    dense
                    clearable
                    label="Academic sessions"
                >
                  <template v-slot:label>
                    Academic sessions
                  </template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.program.session_id.$error">This information is required</span>
              </v-col>

              <v-col cols="12" v-if="field == 'intake'">
                <v-select
                    :items="intakeStatuses"
                    v-model="program.application_intake_status"
                    :error="$v.program.application_intake_status.$error"
                    item-text="name"
                    item-value="value"
                    label="Application intake status"
                    placeholder="Application intake status"
                    outlined
                    dense
                >
                </v-select>
                <span class="text-danger" v-if="$v.program.application_intake_status.$error">This information is required</span>
              </v-col>

              <v-col cols="12" v-if="field == 'session_and_location' && program.session_id">
                <v-autocomplete
                    :error="$v.program.location_id.$error"
                    v-model="location_id"
                    :items="locations"
                    item-text="full_address"
                    item-value="id"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    label="Locations"
                    outlined
                    dense
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-if="field == 'featured'">
                <v-select
                    outlined
                    dense
                    :items="features"
                    v-model="is_featured"
                    :error="$v.program.is_featured.$error"
                    item-text="name"
                    item-value="value"
                    label="Is Feature"
                    placeholder="Is Feature"
                >
                </v-select>
                <span class="text-danger" v-if="$v.program.is_featured.$error">This information is required</span>
              </v-col>

              <v-col cols="12" v-if="field == 'pgwp'">
                <v-select
                    outlined
                    dense
                    :items="pgwpStatus"
                    v-model="is_pgwp"
                    :error="$v.program.is_pgwp.$error"
                    item-text="name"
                    item-value="value"
                    label="Is pgwp"
                    placeholder="Is pgwp"
                >
                </v-select>
                <span class="text-danger" v-if="$v.program.is_pgwp.$error">This information is required</span>
              </v-col>

              <v-col cols="12" v-if="field == 'status'">
                <v-select
                    outlined
                    dense
                    :items="status"
                    v-model="isActive"
                    :error="$v.program.is_active.$error"
                    item-text="name"
                    item-value="value"
                    label="Status"
                    placeholder="Status"
                >
                </v-select>
                <span class="text-danger" v-if="$v.program.is_active.$error">This information is required</span>
              </v-col>

              <v-col cols="12 text-right">
                <v-btn @click="closeDialog()"
                color="mr-2" text dark
                medium
                >Cancel
                </v-btn>
                <v-btn
                    :loading="loading"
                    @click="updateProgram"
                    color="ml-2 btn btn-primary"
                    medium
                >Update
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-app>
</template>

<script>

import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import CountryService from "@/services/country/CountryService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import AcademicProgramSessionService from "@/services/academic/program/session/AcademicProgramSessionService";
import { requiredIf} from "vuelidate/lib/validators";
import LocationService from "@/services/academic/institution/location/LocationService";

const country = new CountryService();
const location = new LocationService();
const educationLevel = new EducationLevelService();
const programSession = new AcademicProgramSessionService();
const institutionService = new InstitutionService();
const academicProgramService = new AcademicProgramService();

export default {
    name: "Program-Index",
    data() {
        return {
            loading: false,
            dialog: false,
            menu: false,
            allselect: false,
            is_pgwp: false,
            isActive: false,
            ids: [],
            field: null,
            is_featured: null,
            programs: [],
            program_sessions: [],
            education_levels: [],
            location_id: [],
            locations: [],
            categories: [],
            total: null,
            perPage: null,
            page: null,
            sort: {
                key: '',
                isAsc: false
            },
            program: {},
            intakeStatus: [
                {name: 'Open', value: 'open'},
                {name: 'Closed', value: 'closed'},
                {name: 'Waitlisted', value: 'waitlisted'},
            ],
            status: [
                {name: 'Active', value: '1'},
                {name: 'Inactive', value: '0'},
            ],
            features: [
                {name: 'Feature', value: '1'},
                {name: 'Not feature', value: '0'},
            ],
            pgwpStatus: [
                {name: 'PGWP', value: '1'},
                {name: 'Not PGWP', value: '0'},
            ],
            search: {
                title: '',
                institution_id: '',
                education_level_id: '',
                application_intake_status: '',
                location_id: '',
                required_document: '',
                is_active: '1',
            },
            intakeStatuses: [
              {name: 'Open', value:'open'},
              {name: 'Closed', value:'closed'},
              {name: 'Waitlisted', value:'waitlisted'},
            ]
        }
    },
    validations: {
      program: {
        location_id: {required: requiredIf(function () {
              return this.field === 'session_and_location';
          })
        },
        session_id: {required: requiredIf(function () {
              return this.field === 'session_and_location' || this.field === 'session';
          })
        },
        application_intake_status: {required: requiredIf(function () {
            return this.field === 'intake';
          })
        },
        is_featured: {required: requiredIf(function () {
            return this.field === 'featured';
          })
        },
        is_pgwp: {required: requiredIf(function () {
            return this.field === 'pgwp';
          })
        },
        is_active: {required: requiredIf(function () {
            return this.field === 'status';
          })
        },
      }
    },
    mounted() {
        this.__getAll();
        this.getCountries();
        this.getAllInstitutions();
        this.getAllEducationLevel();
        this.getLocationByInstitution();
    },

    methods: {
      setAllProgramIds() {
        this.ids = [];
        if(this.allselect) {
          this.programs.forEach((program) => {
            this.ids.push(program.id);
          });
        } else {
          this.ids = [];
        }
      },
      getLocationByInstitution() {
        if(this.search.institution_id) {
          location.getInstitutionAddress(this.search.institution_id).then(response => {
            this.locations = response.data.data
          }).catch(error => {
            this.locations = [];
          });
        } else {
          location.all().then(response => {
            this.locations = response.data.data
          }).catch(error => {
            this.locations = [];
          });
        }
      },
      getAcademicProgramSessions() {
        programSession.all().then(response => {
          this.program_sessions = response.data.academicProgramSessions
        }).catch(error => {
          this.program_sessions = [];
        })
      },

      updateSessionAndIntake(field) {
        if(this.ids.length === 0 || this.search.institution_id == '') {
          this.allselect = false;
          this.ids = [];
          this.$snotify.error("Please select institution and program");
          return;
        }
        this.dialog = true;
        this.field = field;
        if(field === 'session_and_location' || field === 'session') {
          this.getAcademicProgramSessions();
        }
      },

      updateProgram() {
        this.$confirm({
          message: `Are you sure you want to update the selected program ? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              if(this.ids.length > 0) {
                this.program.ids = this.ids;
                if(this.field == 'session_and_location') {
                  this.program.location_id = this.location_id;
                }
                if(this.field == 'intake') {
                  this.program.application_intake_status =  this.program.application_intake_status;
                }
                if(this.field == 'featured') {
                  this.program.is_featured = this.is_featured;
                }
                if(this.field == 'pgwp') {
                  this.program.is_pgwp = this.is_pgwp;
                }
                if(this.field == 'status') {
                  this.program.is_active = this.isActive;
                }

                this.$v.$touch()
                if (this.$v.$error) {
                  setTimeout(() => {
                    this.$v.$reset()
                  }, 3000);
                } else {
                  academicProgramService
                      .updateCustomData(this.program)
                      .then(() => {
                        this.$snotify.success("Updated Successfully ");
                        this.closeDialog();
                        this.__getAll();
                      })
                      .catch(() => {
                        this.$snotify.error("Oops something went wrong");
                      });
                }
              } else  {
                this.$snotify.error("Please select program");
              }
            }
          },
        });
      },

      closeDialog() {
        this.$v.$reset();
        this.dialog = false;
        this.ids = [];
        this.allselect = false;
        this.field = null;
        this.program = {}
        this.is_featured = null;
        this.is_pgwp = false;
        this.isActive = false;
        this.location_id = [];
      },

      getAllInstitutions() {
        institutionService
        .all()
            .then((response) => {
              this.categories = response.data.universities;
            })
      },

      getAllEducationLevel() {
          educationLevel
          .all()
              .then((response) => {
                this.education_levels = response.data.educationLevels;
              })
      },

      __create() {
          this.$router.push({
              name: "program-create"
          });
      },

      getCountries() {
        country
            .getAllCountry()
            .then((response) => {

              this.countries = response.data.data;
            })
            .catch((error) => {
            })
      },

      __edit(programId) {
          this.$router.push({
              name: 'program-edit',
              params: {program_id: programId}
          });
      },

      __delete(id) {
            this.$confirm({
                message: `Are you sure? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        academicProgramService
                            .delete(id)
                            .then((response) => {
                                this.__getAll()
                                this.$snotify.success("Academic Program Deleted Successfully ");
                            })
                            .catch((err) => {
                                this.$snotify.error("Oops something went wrong");
                            });
                    }
                },
            });
        },

        __getAll() {
            this.loading = true;
            academicProgramService
                .paginate(this.search, this.page)
                .then(response => {
                    this.allselect = false;
                    this.programs = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                })
                .catch((err) => {
                    this.$snotify.error("Oops something went wrong");
                }).finally(() => {
                this.loading = false;
            });
        },

        // search
        __search() {
            this.page = 1;
            this.__getAll();
        },

        // CRUD METHODS
        sortedClass(key) {
            return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
        },

        sortBy(key) {
            this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
            this.sort.key = key;
        },

        // router and redirection
        redirectToInstitution() {
            this.$router.push({
                name: "institutions",
            });
        },


    }
}
</script>
<style lang="sass">
    table
        th.sorted
            &.asc::after
                display: inline-block
                content: '▼'
                color: red

            &.desc::after
                display: inline-block
                content: '▲'
                color: red

</style>>
